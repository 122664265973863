import { ThemeProvider } from "styled-components";

import "../src/static/css/style.css";
import { Banner } from "./container/Banner/Banner";
import EventSliders from "./container/EventSliders";
import Footer from "./container/Footer";
import Services from "./container/Services";
import { theme } from "./utils/themeVariables";

const App = () => {
  return (
    <div className="app">
      <ThemeProvider theme={{ ...theme }}>
        <Banner />
        <Services />
        <EventSliders />
        <Footer />
      </ThemeProvider>
    </div>
  );
};

export default App;
