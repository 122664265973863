import styled from "styled-components";

export const DescLightWrapper = styled.span`
  font-family: Jura;
  font-size: 1.3vw;
  line-height: 2vw;
  color: ${(props) => props.color || "#fff"};
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const HeadingBoldWrapper = styled.span`
  font-family: Rubik;
  font-weight: 600;
  font-size: 3vw;
  line-height: 3.5vw;
  color: #fff;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;
