import styled from "styled-components";
import BgImage from "../../static/Icons/background-hero.png";

const HeroWrapper = styled.div`
  background-color: ${({ theme }) => theme["bg-color-app"]};
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
  padding-bottom: 10%;
  @media only screen and (max-width: 768px) {
    clip-path: polygon(0 0, 100% 0%, 100% 97%, 0% 100%);
  }
`;

const BannerWrapper = styled.div`
  background: url(${BgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 0 6vw 5vw 9vw;
  header {
    padding-top: 3vw;
    img {
      width: 16vw;
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    .hero_data {
      width: 37vw;
    }
    .headline {
      display: block;
      margin-top: 5.5vw;
      font-family: Rubik;
      font-weight: 600;
      font-size: 4.3vw;
      line-height: 4.3vw;
      letter-spacing: -0.04em;
      color: #fff;
      .first {
        color: ${({ theme }) => theme["cyan-color"]};
      }
    }
    .desc {
      display: block;
      font-family: Jura;
      font-size: 1.3vw;
      line-height: 2vw;
      margin: 2.5vw 0;
      color: #fff;
    }
    .hero_img {
      img {
        width: 45vw;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0 8vw 12vw 8vw;
    height: auto;
    header {
      padding-top: 5%;
      img {
        width: auto;
      }
    }
    section {
      flex-direction: column;
      .hero_data {
        width: auto;
      }
      .headline {
        font-size: 37px;
        line-height: 42px;
        margin-top: 6%;
      }
      .desc {
        font-size: 16px;
        line-height: 24px;
        margin: 8% 0;
      }
      .hero_img {
        img {
          width: 100%;
          padding-top: 2%;
        }
      }
    }
  }
`;
const ObjectiveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8vw;
  padding-bottom: 3vw;
  .objective-img {
    width: 36vw;
    object-fit: contain;
  }
  section {
    width: 42vw;
    padding-top: 10%;
    color: #fff;
    .objective-title {
      font-family: Rubik;
      font-weight: 600;
      font-size: 1.9vw;
      line-height: 3vw;
      letter-spacing: -0.04em;
      .first {
        color: ${({ theme }) => theme["cyan-color"]};
      }
    }
    .objective-desc {
      font-family: Jura;
      font-size: 1.3vw;
      line-height: 2vw;
      padding-right: 5rem;
      padding-top: 1.5%;
    }
    .interested {
      padding-top: 1.5%;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 10%;
    flex-direction: column-reverse;
    .objective-img {
      width: 100%;
      padding-top: 24px;
      margin-left: -6px;
    }
    section {
      width: auto;
      padding-top: 0;
      .objective-title {
        font-size: 22px;
        line-height: 28px;
      }
      .objective-desc {
        font-size: 16px;
        line-height: 24px;
        padding-top: 6px;
        padding-right: 0;
      }
      .interested {
        padding-top: 6px;
      }
    }
  }
`;

const RazerBladeWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme["bg-color-app"]};
  padding: 60px;
`;
const RazerBlade = styled.div`
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: ${({ theme }) => theme["bg-color-app"]};
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transform: skewY(356deg);
  }
`;

export {
  HeroWrapper,
  BannerWrapper,
  ObjectiveWrapper,
  RazerBlade,
  RazerBladeWrapper,
};
