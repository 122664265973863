import React from "react";
import { ServiceCardStyle, ServiceWrapper, CardWrapper } from "./style";
import ServiceImg from "../../../src/static/Icons/accelerate-journey-size-reduced.png";
import BrainIcon from "../../../src/static/Icons/icon-accelerator-programmes.png";
import EducationIcon from "../../../src/static/Icons/icon-education-workshops.png";
import HackathonIcon from "../../../src/static/Icons/icon-hackathons.png";
import GuidenceIcon from "../../../src/static/Icons/icon-investment-guidance.png";
import MentorIcon from "../../../src/static/Icons/icon-mentorship-sessions.png";
import TrainingIcon from "../../../src/static/Icons/icon-training-bootcamps.png";
import { Button } from "../../components/Button/Button";
import { DescLightWrapper } from "../../components/Headings/style";
import { HeadingBold } from "../../components/Headings";

const Services = () => {
  const ServiceData = [
    {
      title: "Education Workshops",
      img: EducationIcon,
      desc:
        "Beginner to Advanced workshops about the leading crypto concepts, protocols, and tools. ",
    },
    {
      title: "Training Bootcamps",
      img: TrainingIcon,
      desc:
        "Intense training bootcamps, live courses in cohorts to help you become a certified web3 developer.      ",
    },
    {
      title: "Hackathons",
      img: HackathonIcon,
      desc:
        "In-person and hybrid hackathons to help you learn about web3 and build projects together      ",
    },
    {
      title: "Accelerator Programmes",
      img: BrainIcon,
      desc:
        "Guidance for early-stage startups to help them take the leap to the next level - from product to fundraising    ",
    },
    {
      title: "Mentorship Sessions",
      img: MentorIcon,
      desc:
        "Live and AMA sessions with the leading experts in the web3 space to gain insights on the industry      ",
    },
    {
      title: "Investment Guidance",
      img: GuidenceIcon,
      desc:
        "Support and advice from a network of angels and VCs to take the next step towards funding      ",
    },
  ];

  const ServiceCard = ({ img, title, desc }) => {
    return (
      <ServiceCardStyle>
        <img src={img} />
        <p className="title">{title}</p>
        <DescLightWrapper color="#ffd9eb">{desc}</DescLightWrapper>
      </ServiceCardStyle>
    );
  };
  return (
    <>
      <ServiceWrapper>
        <section>
          <div className="service-title">
            <p className="question">What we do?</p>
            <span className="answer">
              <HeadingBold letterSpacing={false}>
                We accelerate your journey into Web3 with the right resources!
              </HeadingBold>
            </span>
          </div>
          <img className="s-img" src={ServiceImg} alt="service_img" />
        </section>
        <CardWrapper>
          {ServiceData.map((item) => {
            const { img, title, desc } = item;
            return <ServiceCard img={img} title={title} desc={desc} />;
          })}
        </CardWrapper>
        <div className="service-btn">
          <a href="#join_now">
            <Button>Join The Community</Button>
          </a>
        </div>
      </ServiceWrapper>
    </>
  );
};
export default Services;
