export const POLKADOT_URL = process.env.REACT_APP_POLKADOT_EVENTS;
export const SOCIAL_LI_URL = process.env.REACT_APP_SOCIAL_LI_EVENTS;
export const BASE_URL = process.env.REACT_APP_LUMA;

export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.REACT_APP_FIREBASE_APP_CHECK_TOKEN;
