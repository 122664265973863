import React from "react";
import {
  BannerWrapper,
  HeroWrapper,
  ObjectiveWrapper,
  RazerBlade,
  RazerBladeWrapper,
} from "./style";
import SchoolOfMetaLogo from "../../static/Icons/theschoolofmeta.png";
import HeroImg from "../../static/Icons/meta-school-hero-graphic-size-reduced.png";
import ObjectiveImg from "../../static/Icons/learn-build-hired-funds.png";
import { Button } from "../../components/Button/Button";
import { DescLight } from "../../components/Headings";

export const Banner = () => {
  return (
    <>
      <HeroWrapper>
        <BannerWrapper>
          <header>
            <img src={SchoolOfMetaLogo} />
          </header>
          <section>
            <div className="hero_data">
              <span className="headline">
                <span className="first">Master Web3 Skills</span>
                <br /> to Build the Future of the Web!
              </span>
              <div className="desc">
                <DescLight>
                  We are a Web3 education community where you come together,
                  hangout, learn, ask, share and build cool stuff to thrive in
                  the Metaverse!
                </DescLight>
              </div>
              <a href="#join_now">
                <Button>Join The Community</Button>
              </a>
            </div>
            <div className="hero_img">
              <img src={HeroImg} />
            </div>
          </section>
        </BannerWrapper>
        <ObjectiveWrapper>
          <img className="objective-img" src={ObjectiveImg} />
          <section>
            <div className="objective-title">
              <span className="first">
                Want to learn about Blockchain, DeFi, NFTs, DAOs, Smart
                Contracts and the Metaverse?
              </span>
              <br /> This is the place for you! ✨
            </div>
            <p className="objective-desc">
              <DescLight>
                Join the rocketship 🚀 and start acquiring the skills needed for
                the web3 economy. Collaborate on projects, build for the future
                of the web, get a job in the web3 space, or raise funding for
                your crypto startup!
              </DescLight>
            </p>
            <p className="objective-desc">
              <DescLight>
                We will guide you along the way with the right insights,
                mentorship and opportunities to help you reach your goals!
              </DescLight>
            </p>
            <a href="#join_now">
              <p className="interested link">I'm interested! ></p>
            </a>
          </section>
        </ObjectiveWrapper>
      </HeroWrapper>
    </>
  );
};
