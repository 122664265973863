import styled from "styled-components";
const ServiceWrapper = styled.div`
  background: linear-gradient(159.33deg, #df496d 4.13%, #451e7a 98.56%);
  margin-top: -25%;
  padding: 0 6vw 0 9vw;
  .service-title {
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 2%;
  }
  section {
    padding-top: 33%;
    font-family: Rubik;
    font-weight: 600;
    display: flex;
    .question {
      font-size: 2vw;
      line-height: 3vw;
      color: #ffc83a;
    }
    .answer {
      font-size: 3vw;
      line-height: 3.5vw;
      color: #fff;
    }
    .s-img {
      width: 33vw;
    }
  }
  .service-btn {
    display: flex;
    padding: 4% 0 6%;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    section {
      .service-title {
        width: 100%;
      }
      flex-direction: column;
      .question {
        font-size: 28px;
        line-height: 41px;
      }
      .answer {
        font-size: 32px;
        line-height: 38px;
      }
      .s-img {
        width: 100%;
        padding: 5vh 0 6vh 0;
      }
    }
    .service-btn {
      padding: 16% 0;
    }
  }
`;
const CardWrapper = styled.div`
  padding: 4% 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(26%, auto));
  row-gap: 10px;
  grid-gap: 70px;
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
`;
const ServiceCardStyle = styled.div`
  .title {
    font-size: 1vw;
    line-height: 2.8vw;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-family: Rubik;
    font-weight: 600;
    color: #fff;
  }
  .desc {
    font-family: Jura;
    font-size: 1.3vw;
    line-height: 2vw;
    color: #ffd9eb;
  }
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 18px;
      line-height: 41px;
    }
    .desc {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
export { ServiceWrapper, ServiceCardStyle, CardWrapper };
