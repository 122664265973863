import React, { useState } from "react";
import { addDoc } from "firebase/firestore";

import { FooterWrapper, PartnersWrapper } from "./style";

import { Button } from "../../components/Button/Button";
import { HeadingBold } from "../../components/Headings";
import WowlabzIcon from "../../static/Icons/wowlabz.png";
import PolkadotIcon from "../../static/Icons/polkadot-india.png";
import SocailliIcon from "../../static/Icons/socialli.png";
import { DescLightWrapper } from "../../components/Headings/style";
import { collRef } from "../../config/axios/firebase";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const SupportCard = ({ img, text, link }) => {
    return (
      <div className="card">
        <a href={link} target="_blank">
          <div className="img-wrap">
            <img src={img} alt="supportImg"></img>
          </div>
        </a>

        <p>
          <DescLightWrapper>{text}</DescLightWrapper>
        </p>
      </div>
    );
  };
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const hadleClick = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      alert("Please enter name and email correctly");
    } else if (!email.match(mailformat)) {
      alert("You have entered an invalid email address!"); //The pop up alert for an invalid email address
    } else {
      setIsLoading(true);
      await addDoc(collRef, { name: name, email: email })
        .then((res) => {
          setSent(true);
          setIsLoading(false);
          console.log(res);
          setName("");
          setEmail("");
        })
        .catch((err) => alert("Submission failed!"));
    }
  };
  return (
    <FooterWrapper id="join_now">
      <p className="title">Stay updated about the events and initiatives</p>
      <section>
        <div className="info">
          <HeadingBold>
            Join the community of creators, developers, entrepreneurs, engineers
            and marketers who will lead the Web3 innovation and transform the
            internet!
          </HeadingBold>
        </div>

        <div className="form">
          {!isSent ? (
            <form>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
              />
              <input
                value={email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email"
              />
              <div className="form-btn">
                <Button
                  disabled={isLoading}
                  type="submit"
                  onClick={(e) => hadleClick(e)}
                >
                  {isLoading ? "Submitting..." : "Join The Community"}
                </Button>
              </div>
            </form>
          ) : (
            <div className="thanks">
              <DescLightWrapper>
                Thank you for your interest, will get back to you{" "}
              </DescLightWrapper>
            </div>
          )}
        </div>
      </section>
      <PartnersWrapper>
        <div className="support-tag">We are supported by</div>
        <div className="cards">
          <SupportCard
            img={PolkadotIcon}
            text="A pan India community building interoperable blockchains"
            link="https://www.polkadotindia.org/"
          />
          <SupportCard
            img={SocailliIcon}
            text="A layer 1 blockchain for helping web2 communities migrate to web3"
            link="https://www.social.li/"
          />
          <SupportCard
            img={WowlabzIcon}
            text="One of India’s leading tech product development firms."
            link="https://www.wowlabz.com/"
          />
        </div>
      </PartnersWrapper>
    </FooterWrapper>
  );
};

export default Footer;
