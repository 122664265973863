import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  FIREBASE_API_KEY,
  FIREBASE_APPCHECK_DEBUG_TOKEN,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from "./constant";

self.FIREBASE_APPCHECK_DEBUG_TOKEN = FIREBASE_APPCHECK_DEBUG_TOKEN;
console.log(
  FIREBASE_API_KEY,
  FIREBASE_APPCHECK_DEBUG_TOKEN,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET
);
const app = initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
});

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("-"),
  isTokenAutoRefreshEnabled: true,
});

const db = getFirestore();
export const collRef = collection(db, "users");
