import React from "react";
import Slider from "react-slick";

import {
  LoadingWrapper,
  NoEventsWrapper,
  SliderCardWrapper,
  SliderWrapper,
} from "../style";
import { HeadingBold } from "../../../components/Headings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { localMoment, useIsTab } from "../../../utils/utils";
import { DescLightWrapper } from "../../../components/Headings/style";

const EventComponent = ({ heading, isLoading, events, isLast }) => {
  console.log(events, heading);
  const isTab = useIsTab();
  const settings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const SliderCard = ({ img, title, date, link }) => {
    return (
      <SliderCardWrapper>
        <div className="event-img">
          <img src={img} alt="event" />
        </div>
        <div className="slider-data">
          <span className="title text">{title}</span>
          <span className="date text">{localMoment(date).format("LLL")}</span>

          <a href={`https://lu.ma/${link}`} target="_blank">
            <div className="link">Learn More ></div>
          </a>
        </div>
      </SliderCardWrapper>
    );
  };

  const TabView = ({ events }) => {
    return (
      <section>
        {events?.map((item) => {
          const { cover_url, url, name, start_at } = item;
          return (
            <SliderCard
              img={cover_url}
              title={name}
              date={start_at}
              link={url}
            />
          );
        })}
      </section>
    );
  };

  const WebView = ({ events }) => {
    return (
      <div className="slider">
        {events.length > 0 ? (
          <Slider {...settings}>
            {events?.map((item) => {
              const { cover_url, url, name, start_at } = item;
              return (
                <SliderCard
                  img={cover_url}
                  title={name}
                  date={start_at}
                  link={url}
                />
              );
            })}
          </Slider>
        ) : (
          <NoEventsWrapper>
            <DescLightWrapper>No Events</DescLightWrapper>
          </NoEventsWrapper>
        )}
      </div>
    );
  };

  return (
    <SliderWrapper isLast={isLast}>
      <HeadingBold letterSpacing={false}>{heading}</HeadingBold>
      {!isLoading ? (
        isTab ? (
          <TabView events={events} />
        ) : (
          <WebView events={events} />
        )
      ) : (
        <LoadingWrapper>
          <DescLightWrapper>Loading...</DescLightWrapper>
        </LoadingWrapper>
      )}
    </SliderWrapper>
  );
};

export default EventComponent;
