import styled from "styled-components";

const FooterWrapper = styled.div`
  padding: 6% 8%;
  background: linear-gradient(135deg, #420fae 0%, #260249 100%);
  font-family: Rubik;
  .title {
    font-weight: 600;
    font-size: 1.8vw;
    line-height: 2.7vw;
    color: ${({ theme }) => theme["cyan-color-v2"]};
  }
  section {
    display: flex;
    .info {
      width: 55%;
      margin-right: 12%;
    }
    .form {
      width: 25%;
      .thanks {
        background: rgba(255, 255, 255, 0.1);
        padding: 1.5rem;
        border-radius: 5px;
        border: 1px solid #6376da;
      }
      input {
        width: 100%;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        padding: 10px 20px;
        padding: 5.5% 5%;
        border: none;
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 1vw;
        line-height: 23px;
        margin: 10px 0;
        color: #c4c4c4;
        ::placeholder {
          color: #c4c4c4;
        }
      }
      input:focus {
        outline: none;
        border: none;
      }
      .form-btn {
        margin-top: 10%;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 28px;
      line-height: 41px;
    }
    section {
      flex-direction: column;
      .info {
        width: auto;
        font-size: 32px;
        line-height: 38px;
        margin-right: auto;
        margin-bottom: 8%;
      }
      .form {
        width: auto;
        input {
          width: fill-available;
          font-size: 15px;
          line-height: 23px;
        }
      }
    }
  }
`;

const PartnersWrapper = styled.div`
  margin: 8% 0 5% 0;
  padding-top: 5%;
  border-top: 1px solid #6376da;
  .support-tag {
    font-family: Rubik;
    margin-bottom: 5%;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    color: #b766f3;
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(26%, auto));
    row-gap: 10px;
    grid-gap: 70px;
    @media only screen and (max-width: 500px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, auto));
    }
    .card {
      .img-wrap {
        height: 60%;
      }
      text-align: center;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 22% 0 50% 0;
    padding-top: 20%;
    .support-tag {
      margin-bottom: 20%;
    }
  }
`;
export { FooterWrapper, PartnersWrapper };
