import React from "react";
import { DescLightWrapper, HeadingBoldWrapper } from "./style";

export const HeadingBold = ({ children, letterSpacing }) => {
  return (
    <HeadingBoldWrapper letterSpacing={letterSpacing}>
      {children}
    </HeadingBoldWrapper>
  );
};

export const DescLight = ({ children, color }) => {
  return <DescLightWrapper color={color}>{children}</DescLightWrapper>;
};
