import { POLKADOT_URL, SOCIAL_LI_URL } from "../config/axios/constant";
import { DataService } from "../config/DataServices";

const getPolkadotEvents = () => {
  return DataService.get(POLKADOT_URL);
};
const getSocialLiEvents = () => {
  return DataService.get(SOCIAL_LI_URL);
};
export { getPolkadotEvents, getSocialLiEvents };
