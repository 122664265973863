import React, { useEffect, useState } from "react";
import EventComponent from "./overview/EventComonent";
import { AllEventsWrapper } from "./style";

import {
  getPolkadotEvents,
  getSocialLiEvents,
} from "../../services/EventsServices";
import { sortByTime, sortByTimeForPast } from "../../utils/utils";

const EventSliders = () => {
  const [state, setState] = useState({
    upComingEvents: [],
    pastEvents: [],
  });
  const { upComingEvents, pastEvents } = state;

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const respPolka = await getPolkadotEvents();
      const respSocial = await getSocialLiEvents();
      const events = [...respPolka.data.events, ...respSocial.data.events];

      // This function splits combined events from polkadot and socialLi in upcoming and past events based on date/time
      const SplitEvents = (items) => {
        const pastEventArray = [];
        const upComingEventsArray = [];
        items.map((item) => {
          if (Date.parse(item.start_at) < Date.now()) {
            pastEventArray.push(item);
          } else {
            upComingEventsArray.push(item);
          }
        });
        setState({
          upComingEvents: sortByTime(upComingEventsArray),
          pastEvents: sortByTimeForPast(pastEventArray),
        });
        setLoading(false);
      };
      SplitEvents(events);
    };
    fetchData();
  }, []);

  return (
    <AllEventsWrapper>
      <EventComponent
        isLoading={isLoading}
        events={upComingEvents}
        heading="Upcoming Events"
        isLast={false}
      />
      <EventComponent
        isLoading={isLoading}
        events={pastEvents}
        heading="Past Events"
        isLast={true}
      />
    </AllEventsWrapper>
  );
};

export default EventSliders;
