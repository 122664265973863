const btnColor = "#2d491f";
const bgColorApp = "#1a1537";
const cyanColor = "#38ffdc";
const cyanColorV2 = "#00D5C8";
const readMoreText = "#2fd69d";

const theme = {
  "btn-color": btnColor,
  "bg-color-app": bgColorApp,
  "cyan-color": cyanColor,
  "cyan-color-v2": cyanColorV2,
  "read-more-color": readMoreText,
};

export { theme };
