import { useEffect, useLayoutEffect, useState } from "react";
import moment from "moment";

export const localMoment = (time) => {
  return moment(time);
};

const checkSize = (width) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < width);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < width;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);
  return isMobile;
};
export const useIsMobile = () => {
  return checkSize(1024);
};
export const useIsTab = () => {
  return checkSize(768);
};

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const sortByTime = (events) => {
  const sorted = events.sort(
    (a, b) => new Date(a.start_at) - new Date(b.start_at)
  );
  return sorted;
};
export const sortByTimeForPast = (events) => {
  const sorted = events.sort(
    (a, b) => new Date(b.start_at) - new Date(a.start_at)
  );
  return sorted;
};
